//* try to retrieve files ( graph )
//! reminder : if i get 401 cors it's likely the token
export async function getInfoFromGraph( token, url) {
    console.log("in function", token);
    console.log(url);
    
    try {
      const response = await fetch(url, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });
      if (!response.ok) {
        console.log(response);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      console.log(response);
      const data = await response.json();
      console.log("Graph data:", data);
      return data;
    } catch (error) {
      console.error("Error fetching Graph content:", error);
      return null;
    }
}
  
//*save file in folder "files" when I got them 
export async function saveFile(fileInfo){
    try {
      const response = await axios.post(
        // `http://localhost:3001/api/assistants/update`, fileInfo);
        `${process.env.BASE_URL}/api/assistants/update`, fileInfo);
      const data = await response.data;  
      console.log("file content res success", data);
    } catch (error) {
      console.error("Error during saveFile execution:", error);
    }
}

export async function updateFile() {}

