const axios = require('axios');
const dotenv = require('dotenv');
dotenv.config();

export async function createThread(docId, userId, openaiId) {
  const filter = { filter: {documentId: docId, userMail: userId} };
  const newDataset = {  document : {documentId: docId, userMail: userId, openaiThreadId: openaiId} };

  const dataResult = await sendApiRequest('findOne',  filter );
  console.log("result", dataResult);
  
  if (dataResult && dataResult.document === null) {
    console.log('No documents found.');
  } else {
      console.log('Documents found for :', dataResult);
  }
  if (dataResult && dataResult.document === null) {
      const editRes = await sendApiRequest('insertOne', newDataset);
      return {success:true, data: editRes};
  } else if(dataResult) {
      console.log("It seems that this data set already exists");
      return {success:true, data: dataResult.document};
  }else{
    console.log("An error occured");
    return {success:false};
  }
}

export async function editThread(docId, userId, openaiId) {

  const filter = { filter: {documentId: docId, userMail: userId} };
  const update = { filter: {documentId: docId, userMail: userId}, update :{ $set: { openaiThreadId: openaiId }} };

  const filterResult = await sendApiRequest('findOne', filter);
  if (filterResult && filterResult.document !== null ) {
      const updateResult = await sendApiRequest('updateOne', update );
      return {success:true, data: updateResult};
  } else{
      console.log("It seems that the data set can't be reached, maybe it doesn't exist");
      return {success:false};
    }
}

//*to display content of folder for files assistant
export async function projectsData(){
  const projData = await sendApiRequestProject('find', "");
  console.log("project request working :", projData);
  return projData;
}

//*to create new match for folder content for file assistant  
export async function createProject(groupId, driveId, itemId, groupName){
  if (!driveId || !itemId || !groupId || !groupName) {
    return {success:false, message: `Missing an element. Have you chosen a folder to operate with ?`}; 
  }
  const checkExist = await issetProject(groupName);
  if(checkExist){
    const newDataset = {  document : {project_name: groupName, project_id: groupId, drive_id: driveId, folder_id:itemId } };
    const newProject = await sendApiRequestProject('insertOne', newDataset);
    console.log("project request working :", newProject);
    return {success:true, data: newProject};;
  }else{
    return {success:false }; 
  }
}

async function issetProject(groupName){
  const filter = { filter: {project_name: groupName} };

  const filterResult = await sendApiRequestProject('findOne', filter);
  if (filterResult && filterResult.document !== null ) {
    console.log("Dataset already exist, you need to delete it in order to create a new one.");
      return false;
    } else{
      console.log("Dataset doesn't exist, you can create this one.");
      return true;
    }
}

async function sendApiRequestProject(action, data) {
  console.log("base url :", process.env.BASE_URL);
  console.log("whole url :", `${process.env.BASE_URL}/api/db-project/${action}`);
  try {
    const response = await axios.post(
      `${process.env.BASE_URL}/api/db-project/${action}`,
      // `http://localhost:3001/api/db/${action}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error('Error in sendApiRequest:', error.response ? error.response.data : error.message);
    throw error;
  }
}

async function sendApiRequest(action, data) {
  console.log("base url :", process.env.BASE_URL);
  console.log("whole url :", `${process.env.BASE_URL}/api/db/${action}`);
  
  try {
    const response = await axios.post(
      `${process.env.BASE_URL}/api/db/${action}`,
      // `http://localhost:3001/api/db/${action}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error('Error in sendApiRequest:', error.response ? error.response.data : error.message);
    throw error;
  }
}
